import { combineReducers } from 'redux';
import commonReducers from '../../common/reducers';
import { mainPostReducer } from './main-post';

export const reducers = combineReducers({
  ...commonReducers,
  mainPost: mainPostReducer,
});

export type AppState = ReturnType<typeof reducers>;
