import model from './model';

export default model.createController(({ $w, $widget, flowAPI }) => {
  return {
    pageReady: async () => {
      console.log('##', 'widgetLoaded 🎈🎈🎈');

      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      ...model.getExports(),
      setPost: (post: any) => {
        console.log('##', 'SET POST CALLED!!!!', post);
      },
    },
  };
});
